<template>
    <div class="student_parent">
        <div class="clearfix student_nav">
            <div class="navig_logos fl">
                <img src="./../../assets/img/logo.png" alt="">
            </div>
            <div class="fr navig_div">
                <div class="navig_title">
                    <h3 class="fl">{{this.$store.state.schName}} - 学生端</h3>
                    <div class="fl navig_divs">
                        <span>项目填报开始截止时间：{{this.$store.state.startTime}} —— {{this.$store.state.endTime}}</span>
                    </div>
                    <span class="fr" @click="exit()">
                        <el-button type="info" size="small" plain>退出</el-button>
                    </span>
                </div>
            </div>
        </div>
        <div class="clearfix student_all">
            <div class="fl naviga_left">
                <el-menu
                    :default-active="activePath"
                    :unique-opened='true'
                    class="el-menu-vertical-demo"
                    background-color="#409eff"
                    text-color="#fff"
                    active-text-color="#ffd04b">
                    <el-submenu index="1">
                        <template slot="title">
                            <i class="iconfont icon-xiangmuguanli-" style="margin-right:10px;font-size:18px"></i>
                            <span>项目管理</span>
                        </template>
                        <router-link :to="this.$store.state.itemId!==null?'Projectview':'Projectinfor'">
                            <el-menu-item :index="this.$route.name=='Projectview'?'Projectview':'Projectapplication'">项目信息</el-menu-item>
                        </router-link>
                        <!-- <router-link to="Projectview">
                            <el-menu-item index="Projectview">项目查看</el-menu-item>
                        </router-link> -->
                        <router-link to="ProjectSchedule">
                            <el-menu-item index="ProjectSchedule">项目日志</el-menu-item>
                        </router-link>
                        <router-link to="Projectpatent">
                            <el-menu-item index="Projectpatent">项目专利</el-menu-item>
                        </router-link>
                        <router-link to="Fundingsupport">
                            <el-menu-item index="Fundingsupport">经费上报</el-menu-item>
                        </router-link>
                        <router-link to="Postprojectreport">
                            <el-menu-item index="Postprojectreport">结项报告</el-menu-item>
                        </router-link>
                    </el-submenu>
                    <router-link to="Studentpassword">
                        <el-menu-item index="Studentpassword">
                            <i class="iconfont icon-zhanghaoguanli" style="margin-right:10px;font-size:18px"></i>
                            <span slot="title">修改密码</span>
                        </el-menu-item>
                    </router-link>
                    <el-menu-item index="3">
                        <i class="iconfont icon-xitongxiaoxi" style="margin-right:10px;font-size:18px"></i>
                        <span slot="title">系统消息</span>
                    </el-menu-item>
                </el-menu>
            </div>
            <div class="fr naviga_right">
                <div class="bread_container">
                    <el-breadcrumb class="breadcrumb" separator="/">
                        <el-breadcrumb-item 
                            v-for='(name,index) in $route.meta'
                            :key='index'
                            >
                            {{name}}
                        </el-breadcrumb-item>
                    </el-breadcrumb>
                </div>
                <div class="bread_view">
                    <router-view></router-view>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
export default {
    data () {
        return {
            activePath:'',
        }
    },
    methods: {
        exit(){
            this.$router.push({name:'login'})
            localStorage.clear()
        },
        getPath(){
            let currentPath = this.$route
            this.activePath = currentPath.name
            if(this.$route.name=='Projectinfor' || this.$route.name=='Newmembers' || this.$route.name=='Newteacher'){
                this.activePath = 'Projectapplication'
            }else if(this.$route.name=='BasicProject' || this.$route.name=='SummaryReport' || this.$route.name=='Useoffunds' || this.$route.name=='Viewclosingreport'){
                this.activePath = 'Postprojectreport'
            }
        },
    },
    watch:{
        $route(){
            this.getPath()
        }
    },
    mounted() {
        this.getPath()
    },
}
</script>
<style scoped>
@import './../../assets/css/student/student.css';
</style>
<style>
.naviga_left .el-menu{
    border-right: none;
}
.naviga_left .el-submenu__title i{
    color: #fff;
}
.naviga_left .el-menu-item i{
    color: #fff;
}
.naviga_left .is-active{
    background: rgb(51, 126, 204)!important;
}
</style>